
export default {
    "status": "Status",
    "save": "Speichern",
    "plugin_info": "Info",
    "global": "Allgemein",
    "contact_us": "Bei Fragen:",
    "toast": {
        "cache_cleared": "Cache erfolgreich geleert!",
        "saved": "Gespeichert!",
        "error_contact_support": "Error! Bitte wenden Sie sich an den Support",
        "issue_comment_added": "Kommentar wurde hinzugefügt",
        "you_have_errors": "Nicht gespeichert. Ein Fehler ist aufgetreten",
        "issue_was_created": "Es wurde ein neues Ticket erstellt.",
        "incorrect_login_data": "Falsche Anmeldeinformationen",
        "incorrect_password_data": "Fehler bei der Passwortänderung",
        "please_check_marked_fields": "Bitte überprüfe die rot markierten Felder."
    },
    "please_select": "Bitte wählen",
    "php_query": {
        "status": "Status",
        "selector": "PHP-Query-Selektor",
        "method": "PHP-Query-Funktion",
        "methods": {
            "append": "append() - Als als letztes Kind in Selektor einfgen",
            "prepend": "prepend() - Als erstes Kind in Selektor einfgen",
            "after": "after() - Nach Selektor einfgen",
            "before": "before() - Vor Selektor einfgen",
            "replaceWith": "replaceWith() - Selektor durch Inhalt ersetzen",
        },
    },
    'page_types': {
        1: 'Artikel',
        2: "Artikelliste",
        18: "Home"
    },
    "plugin_lang_vars": "Sprachvariablen",
    "no_data": 'Keine Daten',
    "confirm_delete": "Bestätigen Sie die Löschung?",
    "settings_saved":"Gespeichert",
    "settings_saved_error":"Speicherfehler",
}
