export default {
    "name": "Triggers",
    "info_text": "",
    "or": "ODER",
    "after_seconds": "Nach Sekunden",
    "triggers_interval_label": "Wählen Sie, wie oft ein Besucher Ihr Popup sehen soll",
    "triggers_interval": {
        "anytime": "Jedes Mal",
        "day": "Einmal pro Tag pro Besuch",
        "week": "Einmal pro Woche pro Besuch",
        "month": "Einmal pro Monat pro Besuch",
        "year": "Nur neue Besucher",
    },
    "triggers": {
        "click": {
            "title": "Mausklick",
            "desc": "Auslöser wird bei einem Mausklick aktiviert",
            "icon": "mouse-pointer",
            "after_clicks": "Anzeigen nach Klicks"
        },
        "timeout": {
            "title": "Nach einer Verzögerung",
            "desc": "Popup erscheint nach einer von Ihnen gewählten Zeitverzögerung",
            "icon": "clock",
        },
        "leave": {
            "title": "Beim Verlassen der Seite",
            "desc": "Besucher ansprechen, bevor sie Ihre Seite verlassen",
            "icon": "window-close",
        },
        "new_user": {
            "title": "Neuer Benutzer",
            "desc": "Auslöser wird aktiviert, wenn sich ein neuer Benutzer registriert",
            "icon": "user-plus",
        },
        "logged_user": {
            "title": "Eingeloggter Benutzer",
            "desc": "Auslöser wird aktiviert, wenn sich ein neuer Benutzer anmeldet",
            "icon": "door-open",
        },
        "inactivity": {
            "title": "Nach Inaktivität",
            "desc": "Popup erscheint, nachdem Ihr Seitenbesucher für eine bestimmte Zeit inaktiv war",
            "icon": "hourglass",
        },
        "immediately": {
            "title": "Sofort",
            "desc": "Ihr Popup erscheint, sobald Ihre Seite geladen wird",
            "icon": "flash",
        },
        "scroll": {
            "title": "Nach Scrollen",
            "desc": "Erscheint, nachdem ein Besucher einen bestimmten Prozentsatz der Seite gescrollt hat",
            "icon": "scroll",
            "after_scrolling": "Nach Prozentsatz des Scrollens"
        },
    },
}
