export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateUniqueId() {
    return Math.random().toString(36).substr(2, 10);
}

export function toCamelCase(key) {
    return key.replace(/_([a-z])/g, function(match, group) {
        return group.toUpperCase();
    });
}
