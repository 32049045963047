export default {
    "name": "Relevante Produktempfehlungen",
    "info_text": "Bieten Sie Ihren Kunden passende Produktempfehlungen, die zum Kauf führen. Basierend auf dem Nutzerverhalten sowie regionalen und saisonalen Trends heben wir Produkte hervor, die wirklich interessant sind. Steigern Sie den durchschnittlichen Bestellwert durch maßgeschneiderte Empfehlungen.",
    "portlets_info_top": "Dieses Plugin bietet fünf verschiedene Portlets, die Sie wie gewohnt über den OnPage Composer von JTL verwenden können. Die Portlets dienen dazu, Ihren Kunden personalisierte Produktempfehlungen zu präsentieren. Hier sind die Funktionen im Detail:",
    "portlets_info_bot": "Diese Portlets tragen dazu bei, das Einkaufserlebnis für Ihre Kunden zu verbessern, indem sie personalisierte und relevante Produktempfehlungen bieten.",
    "portlets": [
        {
            "name": "Empfehlung aus Kategorieverlauf",
            "description": "Dieses Portlet zeigt Produkte aus Kategorien, die der Kunde bereits besucht hat. Wenn ein Kunde beispielsweise auf der Suche nach einem neuen Fernseher war, werden ihm in diesem Bereich Fernseher angezeigt. Diese Funktion hilft, die Produktsuche zu vereinfachen und relevante Artikel schneller zu finden."
        },
        {
            "name": "Empfehlung zur Nachbestellung",
            "description": "In diesem Portlet sieht der Kunde Produkte, die er schon einmal bestellt hat. Dies ist besonders nützlich für regelmäßig benötigte Artikel wie Tiernahrung oder Kaffee. So kann der Kunde seine bevorzugten Produkte schnell und unkompliziert nachbestellen."
        },
        {
            "name": "Regionale Empfehlungen",
            "description": "Hier werden Empfehlungen basierend auf Käufen von Kunden aus derselben Region angezeigt. Zum Beispiel erhalten Kunden im Süden Deutschlands eher Empfehlungen für Bergsteiger-Ausrüstung, während Kunden im Norden Badesachen vorgeschlagen werden. Diese Funktion berücksichtigt regionale Präferenzen und Kaufverhalten."
        },
        {
            "name": "Intelligente Empfehlungen",
            "description": "Dieses Portlet bietet eine Mischung aus allen anderen Portlets und nutzt einen speziellen Algorithmus, um dem Kunden relevante Empfehlungen zu präsentieren. Dies sorgt für eine personalisierte und vielfältige Auswahl an Produkten, die den individuellen Vorlieben und dem Kaufverhalten des Kunden entsprechen."
        },
        {
            "name": "Empfehlung aus Benutzerverlauf",
            "description": "Hier kann der Kunde die zuletzt aufgerufenen Produkte einsehen und diese auf einer eigenen Unterseite betrachten. Diese Funktion erinnert den Kunden daran, welche interessanten Produkte er sich bereits angeschaut hat, und animiert ihn zum Kauf."
        },
    ]
}
