export default {
    "description": {
        "info_text": "Produktbundles sind die ultimative Lösung für erfolgreiches Upselling. Mit nur wenigen Klicks erstellen Sie automatisierte Empfehlungen, die perfekt zu den Produkten passen, die Ihre Kunden gerade betrachten. Optimieren Sie das Cross-Selling und steigern Sie mühelos den durchschnittlichen Bestellwert. ",
        "dynamic_products": "Dynamische Bundles mit wenigen Klicks",
        "static_products": "Statische Zusatzprodukte die Sie individuell auswählen"
    },
    "columns": {
        "name": "Name",
        "type": "Typ",
        "element": "Element",
        "elements": "Inhalt",
        "discount": "Rabatt",
        "active": "Aktiv"
    },
    "choose_bundle_type": "Wählen Sie aus ob Ihr Bundle auf allen Produkten einer Kategorie oder nur bei einem bestimmten Produkt angezeigt werden soll",
    "add": "Hinzufügen",
    "back": "Zurück",
    "category": "Kategorie",
    "product": "Produkt",
    "discount": "Rabatt in Prozenten",
    "static_product": "Statisches Produkt",
    "dynamic_product": "Dynamische Produkte",
    "add_filter": "Filtern",
    "exclude_product": "Produkte ausschließen",
    "dynamic_category": "Dynamische Produkte aus einer Kategorie",
    "edit": "Bearbeiten",
    "minimum_search_category": "Beginnen Sie mit der Eingabe, um nach einer Kategorie zu suchen (mind. 3 Symbole).",
    "type_notice": "Tippen um zu suchen",
    "exclude_products_placeholder": "Hier eintippen um zu suchen",
    "bundle_not_valid": "Es gibt Produkte in diesem Bundle, die nicht bestellt werden können.",
    "invalid_element": "Dieses Produkt kann nicht bestellt werden, da die Menge 0 beträgt.",
}
