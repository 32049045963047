<template>
  <div class="mb-3">
    <ImageUploadWidget
        ref="inputWidget"
        :name="inputName"
        input-class="upload-banner_logo"
        return-src
        show-preview
        @change="changeFile($event)"
        v-on:upload-widget:src="changePreview"
        :check-image="false"
        :src="inputValue"/>
    <div  class="d-flex justify-content-center mt-2">
      <button v-if="showBtnClose" class="btn btn-danger btn-sm" @click="resetView">
        <span class="fs-10 fas fa-trash-alt"></span>
      </button>
      <button v-else class="btn btn-primary btn-sm" @click="upload">
        <span class="fs-10 fas fa-upload"></span> {{ $t('PopupBuilder.image_upload') }}
      </button>
    </div>
  </div>
</template>

<script>
import ImageUploadWidget from "../../../../../../../adminmenu/view/components/ImageUploadWidget";

export default {
  components: {ImageUploadWidget},
  props: {
    value: String,
    showBtnClose: Boolean,
  },
  data() {
    return {
      inputName: `input-name-${Math.random().toString(36).substr(2, 10)}`
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    upload(){
      this.$refs.inputWidget.selectFile();
    },
    changePreview(preview) {
      this.$emit('changePreview', preview);
    },
    changeFile({target}) {
      const files = Array.from(target.files);
      this.$emit('changeFile', files[0]);
    },
    resetView() {
      this.$emit('resetView');
    }
  }
};
</script>
