<template>
  <component :is="dynamicElementComponent" :editorMode="editorMode" :element="element" :closePopup="closePopup"/>
</template>

<script>
import {elementMixin, editorModeMixin} from './mixins/PropsMixin';
import {capitalizeFirstLetter, generateUniqueId, toCamelCase} from "../../../../../adminmenu/view/utils/stringUtils";
import {defineAsyncComponent} from "vue";

export default {
  mixins: [elementMixin, editorModeMixin],
  mounted() {
    if (!this.element.uniqueId) {
      this.element.uniqueId = generateUniqueId();
    }
  },
  computed: {
    dynamicElementComponent() {
      const capitalize = capitalizeFirstLetter(toCamelCase(this.element.type));
      return defineAsyncComponent(() => import(`./elements/${capitalize}.vue`))
    }
  },
}
</script>
