<template>
  <div class="mb-3">
    <ColorPicker :id="inputId"
                 :colorHex="inputValue"
                 :labelText="label"
                 :enableRgb="true"
                 @update:colorHex="inputValue = $event"/>
  </div>
</template>

<script>
import ColorPicker from "../../../../../../../adminmenu/view/components/ColorPicker";

export default {
  components: {ColorPicker},
  props: {
    value: String,
    label: String,
    defaultValue: String,
  },
  data() {
    return {
      inputId: `input-${Math.random().toString(36).substr(2, 10)}`
    };
  },
  mounted() {
    if ((typeof this.inputValue === 'undefined' || this.inputValue === null || this.inputValue === '') && this.defaultValue) {
      this.inputValue = this.defaultValue;
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
}
</script>
<style lang="scss">
.input-group-colorpicker {
  display: flex;
  flex-flow: nowrap;

  .colorpicker-input {
    width: 100%;
  }
}
</style>
