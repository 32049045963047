<template>
  <ModeView v-if="editorMode === EDITOR_MODE_VIEW" :element="element">
    <slot :name="EDITOR_MODE_VIEW"></slot>
  </ModeView>
  <ModeEditor v-else-if="editorMode === EDITOR_MODE_EDITOR" :element="element">
    <slot :name="EDITOR_MODE_EDITOR"></slot>
  </ModeEditor>
  <ModeMenu v-else-if="editorMode === EDITOR_MODE_MENU">
    <slot :name="EDITOR_MODE_MENU"></slot>
  </ModeMenu>
  <ModeIcon v-else-if="editorMode === EDITOR_MODE_ICON" :element="element">
    <slot :name="EDITOR_MODE_ICON"></slot>
  </ModeIcon>
</template>

<script>
import {elementMixin, editorModeMixin, editorModeTypesMixin} from '../mixins/PropsMixin';
import ModeIcon from "./ModeIcon";
import ModeMenu from "./ModeMenu";
import ModeEditor from "./ModeEditor";
import ModeView from "./ModeView";

export default {
  components: {ModeView, ModeIcon, ModeMenu, ModeEditor},
  mixins: [elementMixin, editorModeMixin, editorModeTypesMixin],
}
</script>
