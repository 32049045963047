export default class PopupBuilder {
    constructor(createVuePopup) {
        this.createVuePopup = createVuePopup;
        this.vueInstances = {};
        this.designs = {};
    }

    getVueInstance(designId) {
        return this.vueInstances[designId] || null;
    }

    setVueInstance(designId, vue) {
        this.vueInstances[designId] = vue;
    }

    removeInstance(designId) {
        const vueInstance = this.getVueInstance(designId);
        if (vueInstance) {
            vueInstance.$destroy();
            if (vueInstance.$el && vueInstance.$el.parentNode) {
                vueInstance.$el.parentNode.removeChild(vueInstance.$el);
            }
            delete this.vueInstances[designId];
        }
    }

    getDesign(designId) {
        return this.designs[designId];
    }

    setDesign(designId, design) {
        this.designs[designId] = design;
    }

    removeDesign(designId) {
        delete this.designs[designId];
    }

    async openPopup(designId) {
        let template = this.getDesign(designId);
        if (!template) {
            try {
                template = await this.loadDesign(designId);
                this.setDesign(designId, template);
            } catch (error) {
                console.error('Error loading design:', error);
                return;
            }
        }
        this.createOrUpdateInstance(designId, template);
    }

    createOrUpdateInstance(designId, template) {
        this.closePopup(designId);
        const vue = this.createVuePopup(template);
        this.setVueInstance(designId, vue);
    }

    loadDesign(designId) {
        return new Promise((resolve, reject) => {
            $.evo.io().call('getDesignPopupUpsellCloud', [designId], {}, function (error, {template}) {
                if (error) {
                    reject(error);
                } else {
                    resolve(template);
                }
            });
        });
    }

    closePopup(designId) {
        this.removeInstance(designId);
        this.removeDesign(designId);
    }
}
