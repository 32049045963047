<template>
    <div class="">
        <div :class="{'has-error': errors.has(id), 'is-valid': !errors.has(id)}">
            <label class="form-label">{{ labelText }}</label>
            <div class="input-group input-group-colorpicker">
                <div class="input-group-btn">
                    <button class="btn" type="button" ref="button">
                        <span :style="{background: localColorHex}"></span></button>
                </div>
                <input type="text"
                       :name="id"
                       class="colorpicker-input"
                       v-model="localColorHex"
                       @input="colorChange($event.target)">
            </div>
            <div class="alert alert-danger border-2 d-flex align-items-center mt-2"
                 v-if="errors.has(id)">
                <div class="bg-danger me-3 icon-item"><span class="fas fa-times-circle text-white fs-3"></span>
                </div>
                <p>{{ errors.first(id) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ColorPicker",
    data() {
        return {
            localColorHex: this.colorHex
        }
    },
    created() {
        this.$validator = this.$parent.$validator;
    },
    watch: {
        colorHex: {
            handler(colorHex) {
                this.localColorHex = colorHex;
                $(this.$refs.button).spectrum('set', colorHex);
            },
        },
    },
    mounted() {
       const enableRgb = !!this.enableRgb;

        $(this.$refs.button).spectrum({
            color: this.localColorHex,
            allowEmpty: true,
            preferredFormat: enableRgb ? "rgb" : "hex",
            showInitial: true,
            showInput: true,
            showPalette: true,
            showAlpha: enableRgb,
            change: (value) => {
              if (value) {
                let color;
                if (enableRgb) {
                  color = value.toRgbString();
                } else {
                  color = value.toHexString();
                }
                this.localColorHex = color;
                this.$emit('update:colorHex', color);
              }
            }
        })
    },
    props: {
        id: {
            type: String,
            required: true
        },
        colorHex: String,
        labelText: String,
        enableRgb: Boolean
    },
    methods: {
        colorChange(el) {
            $(this.$refs.button).spectrum('set', el.value);
            this.$emit('update:colorHex', el.value);
        }
    },
    beforeDestroy() {
      $(this.$refs.button).spectrum("destroy");
    }
}
</script>
<style lang="scss">
.input-group-colorpicker {
    button {
        background-color: #eef3f9;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        padding: 4px;
        height: 100%;

        span {
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 3px;
        }

        &:active,
        &:focus,
        &:visited {
            border: 2px solid #2F81A3 !important;
            border-right: none !important;
        }
    }

    input[type=text] {
        border-left: none;
        border-radius: 0 4px 4px 0;
        padding-left: 10px;

        &:focus {
            border-color: #9acffa;
            box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
            outline: 0;
        }
    }
}
</style>
