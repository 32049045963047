export default {
    "name": "Popup Builder",
    "templates_name": "Templates",
    "info_text": "Unser Modul erlaubt es, individuell gestaltete Popups aus Vorlagen zu generieren und sie genau dann anzuzeigen, wann Sie es möchten. Ob Exit-Intent, Zeitbasiert oder andere sinnvolle Szenarien, die den Kunden vom Absprung zum Kauf bewegen sollen – mit diesem Modul erstellen Sie Kaufanreize im Handumdrehen.",
    "templates":"Vorlage #",
    "no_data": "Sie haben noch keine Popups erstellt",
    "width": "Breite",
    "height": "Höhe",
    "delete": "Löschen",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "preview": "Vorschau",
    "status": {
        "active": "Aktiv",
        "inactive": "Inaktiv"
    },
    "back": "Zurück",
    "copy": "Duplizieren",
    "preview_for_site": "Speichern und Vorschau im Shop",
    "image_url": "Bild URL",
    "image_upload": "Hochladen",
    "background_color": "Hintergrundfarbe",
    "padding": "Abstand",
    "margin": "Margin",
    "triggers": "Triggers",
    "triggers_desc": "Konfigurieren Sie mühelos anspruchsvolle Zielgruppeneinstellungen mit wenigen Klicks,\n" +
        "damit Ihre Popups die richtige Zielgruppe erreichen, auf relevanten Seiten erscheinen und zum optimalen Zeitpunkt ausgelöst werden.",
    "click_to_edit": "Bearbeiten",
    "enter_text": "Enter text...",
    "confirm_copy": "Sie werden zur Seite zur Erstellung eines neuen Popups mit den aktuellen Einstellungen weitergeleitet.",
};
