import Vue from 'vue'
import VueI18n from 'vue-i18n'

import de from './locales/de'

const modules = {},
    context = require.context('../../Plugins', true, /\.\/.*\/adminmenu\/i18n\/locales\/de\.js$/);
context.keys().map(key => {
  const regex = /\/([^/]+)\//,
      match = key.match(regex);
  if (match[1]){
    modules[match[1]] = context(key).default;
  }
});

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  messages: {
    de: de
  },
})
const messages = {
  'de': [modules]
};

Object.keys(messages).forEach(locale => {
  messages[locale].forEach(message => {
    i18n.mergeLocaleMessage(locale, message);
  });
});

export default i18n;