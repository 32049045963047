export default {
    "top_bar_info_title": "Überschrift",
    "top_bar_info_description": "Text",
    "top_bar_info_color": "Background color",
    "global": "Allgemein",
    "selector": "PHP-Query-Selektor",
    "pq": "PHP-Query-Funktion",
    "border": "Rand",
    "width": "Dicke",
    "color": "Farbe",
    "title": "Überschrift",
    "font_size": "Schriftgröße",
    "font_weight": "Schriftgewicht",
    "font_style": "Font style",
    "body": "Fließtext",
    "background": "Hintergrundfarbe",
    "info_text": "Erregen Sie die Aufmerksamkeit Ihrer Kunden! Unser Plugin platziert eine anpassbare Leiste oben auf jeder Shopseite, ideal für Angebote und wichtige Hinweise. Einfache Integration für eine effektive Kundenkommunikation."
}
