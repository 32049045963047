<template>
  <div class="upsell-popup-wrapper upsell-popup-bg" :style="style" @click.stop>
    <component :template="template" :is="dynamicTemplateComponent">
      <template v-if="elements" v-for="element in elements" :slot="element.slot">
        <Element :element="element" :editorMode="editorMode" :closePopup="closePopup"></Element>
      </template>
    </component>
  </div>
</template>

<script>
import {templateMixin, editorModeMixin, popupMethods} from './mixins/PropsMixin';
import {defineAsyncComponent} from "vue";
import {capitalizeFirstLetter, toCamelCase} from "../../../../../adminmenu/view/utils/stringUtils";
import Element from './Element';
import {formatDimensionValue} from "./utils/utils";

export default {
  mixins: [templateMixin, editorModeMixin, popupMethods],
  components: {Element},
  computed: {
    dynamicTemplateComponent() {
      const capitalize = capitalizeFirstLetter(toCamelCase(this.templateId));
      return defineAsyncComponent(() => import(`./templates/${capitalize}.vue`))
    },
    style() {
      return {
        padding: formatDimensionValue(this.options.padding),
      };
    },
  }
}
</script>
