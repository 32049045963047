export default {
    "name": "Versandanzeige",
    "background": "Benachrichtigungshintergrund (optional)",
    "font_color": "Textfarbe (optional)",
    "info_text": "Dieses Modul informiert Kunden direkt auf der Artikelseite darüber, bis wann sie bestellen müssen, um ihre Produkte rechtzeitig zu erhalten. Dies motiviert zu schnelleren Kaufentscheidungen und steigert die Konversionsraten.",
    "max_hour": "letzte Zeit für schnellen Versand",
    "message": "Text",
    "message_codes": "Codes\n" +
        "<br> [max-today-hour] – Endzeit\n" +
        "<br> [estimated-delivery-date] - voraussichtliches Lieferdatum",
    "delay_shipping": "Versandverzögerung (Tage)",
    "delivery_days": "Versandtage",
    "week_days": [
        "",//zero day
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag"
    ],
    'holidays': "Holidays",
    "errors": {
        "add_holiday": {
            "name": "Das Namensfeld darf nicht leer sein",
            "date": "Geben Sie das korrekte Datum ein",
            "date_used": "Das Datum ist bereits in Gebrauch",
        }
    },
    "add_holiday_main": "Feiertag hinzufügen",
    "date": "Datum",
    "country": "Land",
}
