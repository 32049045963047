export default {
    "name": "Aftersell: Social Media",
    "info_text": "Stärken Sie Ihre Kundenbindung! Unser Plugin zeigt auf der Bestellbestätigungsseite eine Leiste mit Ihren Social-Media-Kanälen an, um nach dem Verkauf die Interaktion zu fördern. Steigern Sie die Reichweite und bauen Sie langfristige Beziehungen auf.",
    "title": "Geben Sie hier eine Überschrift für die Social Media Leiste ein",
    "add_new": "Neue hinzufügen",
    "link": "Link zu Social Media hinzufügen\n",
    "image": "Portal Logo",
    "actions": "Aktionen",
    "update": "Aktualisieren",
    "delete": "Löschen",
    "notifications": {
        "status_changed": "Plugin-Status wurde geändert",
        "status_failed": "Fehler beim Ändern des Plugin-Status",
        "title_changed": "Plugin-Titel wurde geändert",
        "title_failed": "Fehler beim Ändern des Plugin-Titels",
        "record_update": "Datensatz wurde erfolgreich aktualisiert",
        "record_update_failed": "Fehler beim Aktualisieren des Datensatzes",
        "record_deleted": "Datensatz wurde erfolgreich gelöscht",
        "record_delete_failed": "Fehler beim Löschen des Datensatzes",
    }
}
