<template>
  <div class="upsell-popup-bg-wrapper" @click="closePopup">
    <div class="upsell-popup-bg-container">
      <div class="upsell-popup-bg-content">
        <Template :template="template" :editorMode="EDITOR_MODE_VIEW" :closePopup="closePopup"></Template>
      </div>
    </div>
  </div>
</template>

<script>
import Template from "./Template";
import {templateMixin, editorModeTypesMixin} from './mixins/PropsMixin';

require.context('./templates', false, /\.vue$/);
require.context('./elements', false, /\.vue$/);

export default {
  name: "Popup",
  mixins: [templateMixin, editorModeTypesMixin],
  props: {
    fromSite: Boolean
  },
  components: {Template},
  mounted() {

  },
  methods: {
    closePopupFromWrapper(event){
      if (this.$refs.wrapper.$el === event.target) {
        this.closePopup();
      }
    },
    closePopup() {
      if (this.fromSite) {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      } else {
        this.$emit('closePopup');
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.upsell-popup-bg-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow-y: auto;
}

.upsell-popup-bg-container {
  position: absolute;
  width: 100%;
  max-width: 800px;
  padding: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.upsell-popup-bg-content {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Пример отступа снизу */
}
</style>
