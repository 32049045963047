import {
    EDITOR_MODE_VIEW,
    EDITOR_MODE_MENU,
    EDITOR_MODE_EDITOR,
    EDITOR_MODE_ICON,
    EDITOR_MODE_TYPES
} from '../store/types';
import {formatDimensionValue} from "../utils/utils";

export const templateMixin = {
    props: {
        template: {
            type: Object,
            required: true
        }
    },
    computed: {
        templateId() {
            return this.template?.templateId;
        },
        elements() {
            return this.template?.elements;
        },
        options() {
            return this.template?.options;
        },
        triggers() {
            return this.template?.triggers;
        },
        triggersInterval() {
            return this.template?.triggers_interval;
        },
    }
};

export const editorModeMixin = {
    props: {
        editorMode: {
            type: String,
            default: EDITOR_MODE_VIEW,
            validator(value) {
                return EDITOR_MODE_TYPES.includes(value)
            }
        }
    }
};

export const editorModeTypesMixin = {
    created: function () {
        this.EDITOR_MODE_VIEW = EDITOR_MODE_VIEW;
        this.EDITOR_MODE_MENU = EDITOR_MODE_MENU;
        this.EDITOR_MODE_EDITOR = EDITOR_MODE_EDITOR;
        this.EDITOR_MODE_ICON = EDITOR_MODE_ICON;
    }
};


export const popupMethods = {
    props: {
        closePopup: {
            type: Function
        }
    }
};


export const elementMixin = {
    mixins: [popupMethods],
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    computed: {
        elementOptions() {
            return this.element?.options;
        },
        elementExternalStyle() {
            return {
                padding: formatDimensionValue(this.elementOptions.externalPadding)
            }
        }
    },
}

