export default {
    namespaced: true,
    state: () => ({
        activeElement: null,
        files: {},
        previews: {},
    }),
    mutations: {
        setActiveElement(state, activeElement) {
            state.activeElement = activeElement;
        },
        setPreview(state, {uniqueId, preview}) {
            state.previews = {...state.previews, [uniqueId]: preview};
        },
        setPreviews(state, previews) {
            state.previews = previews;
        },
        setFile(state, {uniqueId, file}) {
            state.files = {...state.previews, [uniqueId]: file};
        },
        setFiles(state, files) {
            state.files = files;
        },
    },
    actions: {
        clear(ctx) {
            ctx.commit('setActiveElement', null);
            ctx.commit('setPreviews', {});
            ctx.commit('setFiles', {});
        }
    },
    getters: {
        getActiveElement(state) {
            return state.activeElement;
        },
        getPreviews(state) {
            return state.previews;
        },
        getFiles(state) {
            return state.files;
        }
    },
};


