import { render, staticRenderFns } from "./Close.vue?vue&type=template&id=07176b76&scoped=true"
import script from "./Close.vue?vue&type=script&lang=js"
export * from "./Close.vue?vue&type=script&lang=js"
import style0 from "./Close.vue?vue&type=style&index=0&id=07176b76&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07176b76",
  null
  
)

export default component.exports