export default {
    "critical_count": {
        "label": "Anzahl für sehr knappen Bestand",
        "text": "Text",
        "color_label": "Farbe"
    },
    "warning_count": {
        "label": "Anzahl für knappen Bestand",
        "text": "Text",
        "color_label": "Farbe"
    },
    "info_text": "Mit unserem Plugin können Sie Ihren Kunden die genaue Anzahl der noch verfügbaren Produkte anzeigen. Dadurch entsteht ein Kaufbedürfnis, da die Produkte knapp sind, und Kunden werden motiviert, sofort zu handeln, um nichts zu verpassen."
}
