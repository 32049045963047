<template>
  <ModeSwitcher :element="element" :editorMode="editorMode">
    <template v-slot:view>
      <div class="upsell-popup-editor" :style="style">
        <div class="quillWrapper">
          <div class="ql-container ql-bubble">
            <div class="ql-editor" v-html="elementOptions.html"/>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:editor>
      <div class="upsell-popup-editor" :style="style">
        <VueEditor ref="editor"
                   :placeholder="$t('PopupBuilder.enter_text')"
                   v-model="elementOptions.html"
                   :editor-toolbar="customToolbar"
                   :editorOptions="{theme: 'bubble'}"/>
      </div>
    </template>
    <template v-slot:icon>
      <span class="fas fa-font fs-4"></span>
    </template>
    <template v-slot:menu>
      <div class="row mb-3">
        <div class="col-12">
          <Color v-model="elementOptions.backgroundColor" :label="$t('PopupBuilder.background_color')"/>
        </div>
        <div class="col-6">
          <Range v-model="elementOptions.padding" :label="$t('PopupBuilder.padding')"/>
        </div>
        <div class="col-6">
          <Range v-model="elementOptions.externalPadding" :label="$t('PopupBuilder.margin')"/>
        </div>
      </div>
    </template>
  </ModeSwitcher>
</template>

<script>
import {VueEditor} from "vue2-editor";

import {elementMixin, editorModeMixin} from '../mixins/PropsMixin';
import ModeSwitcher from "../components/ModeSwitcher";
import Input from "./properties/Input";
import Color from "./properties/Color";
import Range from "./properties/Range";
import {formatDimensionValue, selectText} from '../utils/utils'
import {mapGetters} from "vuex";

export default {
  mixins: [elementMixin, editorModeMixin],
  data: () => ({
    customToolbar: [
      [{header: [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline", "strike"], // toggled buttons
      [{
        align: ""
      }, {
        align: "center"
      }, {
        align: "right"
      }, {
        align: "justify"
      }], [{
        list: "ordered"
      }, {
        list: "bullet"
      }, {
        list: "check"
      }], [{
        indent: "-1"
      }, {
        indent: "+1"
      }],
      [{
        color: []
      }, {
        background: []
      }],
      ["link", "video"], ["clean"],
      [{'font': []}],
    ]
  }),
  components: {
    Range,
    Color,
    ModeSwitcher,
    Input,
    VueEditor
  },
  mounted() {
    const quill = this.quill;
    if (quill) {
      quill.on("selection-change", (range) => {
        if (range) {
          quill.theme.tooltip.show();
          quill.theme.tooltip.position(quill.getBounds(range));
        }
      });
    }
  },
  watch: {
    isActive(val) {
      const quill = this.quill;
      if (quill) {
        if (val) {
          selectText(quill.selection.root);
        } else {
          quill.theme.tooltip.hide();
        }
      }
    }
  },
  computed: {
    ...mapGetters('PopupBuilder/Builder', ['getActiveElement']),
    quill() {
      return this.$refs.editor?.quill;
    },
    isActive() {
      return this.getActiveElement === this.element;
    },
    style() {
      return {
        backgroundColor: this.elementOptions.backgroundColor,
        padding: formatDimensionValue(this.elementOptions.padding),
      };
    }
  },
}
</script>
